<template>
  <div class="content" style="background: #f6f7fb">
    <div v-show="!addVisiable && !detailVisiable && !examineVisiable">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button
            type="primary"
            @click="addVisiable = true"
            icon="el-icon-plus"
            >新增拆改单</el-button
          >
          <!-- <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2' :loading="loading3">导出</el-button> -->
        </div>
        <div class="line"></div>
        <div class="middle">
          <Cond @onCond="handleCond" function_code="split" :mode="mode" />
        </div>
        <div class="right" style="display: flex">
          <TableConf
            function_code="split"
            @onRowList="handleRowList"
            :mode="mode"
          />
          <PrintConf function_code="splitPrint" @onRowList="handlePrintRow" />
          <el-button @click="changeMode" style="margin-left: 10px; height: 40px"
            >切换版式：{{ mode === 1 ? "货品模式" : "单号模式" }}</el-button
          >
        </div>
      </div>
      <div class="total">
        <div class="total-right">
          <div class="total-right-item">
            总数量 <span>{{ data.goods_total || 0 }}</span>
          </div>
          <div class="total-right-item">
            总净金重 <span>{{ data.gold_weight || "0.00" }}</span>
          </div>
        </div>
      </div>
      <div
        class="table"
        v-if="rowList.length"
        style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden"
      >
        <el-table
          :data="data.list"
          stripe
          v-loading="loading"
          height="660"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </template>
                <template v-else-if="item.field_alias === 'split_status'">
                  <span>
                    {{
                      scope.row[item.field_alias] === 10
                        ? "待审核"
                        : scope.row[item.field_alias] === 20
                        ? "审核通过"
                        : scope.row[item.field_alias] === 30
                        ? "审核不通过"
                        : ""
                    }}
                  </span>
                </template>
                <template v-else>
                  {{
                     (scope.row[item.field_alias]) || (scope.row[item.field_alias] === 0)
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </template>
                <template v-else-if="item.field_alias === 'split_status'">
                  <span>
                    {{
                      scope.row[item.field_alias] === 10
                        ? "待审核"
                        : scope.row[item.field_alias] === 20
                        ? "审核通过"
                        : scope.row[item.field_alias] === 30
                        ? "审核不通过"
                        : ""
                    }}
                  </span>
                </template>
                <template v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            field_alias="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option
                    label="审核"
                    :value="2"
                    v-if="scope.row.split_status === 10"
                  ></el-option>
                  <!-- <el-option label="查看失败原因" :value="3" v-if="scope.row.split_status === 30"></el-option> -->
                  <el-option
                    label="编辑"
                    :value="4"
                    v-if="scope.row.split_status === 30 ||
                      noSockIds.includes(scope.row.split_id)"
                  ></el-option>
                  <el-option label="打印" :value="5"></el-option>
                  <el-option
                    label="解锁"
                    v-if="
                      scope.row.split_status === 20 &&
                      !noSockIds.includes(scope.row.split_id)
                    "
                    :value="20"
                  ></el-option>

                  <el-option
                    label="删除"
                    :value="22"
                    v-if="
                      scope.row.split_status === 30 ||
                      noSockIds.includes(scope.row.split_id)
                    "
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          :current-page="page"
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="data.total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <!-- <el-dialog
        title="请先选择品类"
        :visible.sync="dialogVisible"
        width="30%"
        :destroy-on-close="true"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="品类" label-width="100px" required prop="goods_type_id">
            <el-select v-model="form.goods_type_id">
              <template v-for="item in select">
                  <el-option :label="item.goods_type_name" :value="item.goods_type_id" :key="item.goods_type_id">
                    <span :style="{marginLeft: item.span * 8 +'px'}">{{item.goods_type_name}}</span>
                  </el-option>
                </template>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog> -->
    </div>
    <el-dialog
      title="失败原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>
        {{ currRow.split_remark || "--" }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
    <Add
      v-if="addVisiable"
      :split_id="currRow.split_id"
      @hide="handleHideAdd"
      @getList="getList"
      :type="type"
    />
    <Detail
      v-if="detailVisiable"
      :goods_type_id="currRow.goods_type_id"
      :propObj="propObj"
      @hide="handleHideDetail"
      :showPrint="true"
      :title="title"
    />
    <Examine
      v-if="examineVisiable"
      :split_id="currRow.split_id"
      @hide="handleHideExamine"
      @getList="getList"
    />
  </div>
</template>

<script>
import {
  listReq,
  getSplitPrintReq,
  handelCanceled,
  cancelDel,
} from "@/api/goods/goodsHandler/change/list";
// import {
//   getListReq as getTypeListReq
// } from '@/api/goods/goodsConfig/classify';
import moment from "moment";
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import Add from "./Add.vue";
import Detail from "./Detail.vue";
import Examine from "./Examine.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import { getIsLock } from "@/api/monthSettle/index.js";

export default {
  data() {
    return {
      type: 'add', // 类型
      noSockIds: [],
      title: "拆改单",
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      mode: 1,
      loading3: false,
      reasonVisible: false,
      currRow: {},
      select: [],
      propObj: {},
      addVisiable: false,
      detailVisiable: false,
      examineVisiable: false,
      rowList: [
        // {
        //   field_text: '仓库名称',
        //   field_alias: 'warehouse_name',
        // },
        // {
        //   field_text: '所属门店',
        //   field_alias: 'hierarchy_name',
        // },
        // {
        //   field_text: '状态',
        //   field_alias: 'warehouse_status',
        // },
        // {
        //   field_text: '添加人',
        //   field_alias: 'create_user_name',
        // },
        // {
        //   field_text: '添加时间',
        //   field_alias: 'create_time',
        // },
      ],
      loading2: false,
      rules: {
        goods_type_id: [{ required: true, message: "请选择品类" }],
      },
      merchantList: [],
      loading: false,
      form: {},
      dialogVisible: false,
      total: 0,
      tableVisiable: false,
      cond: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      // coordinate: { column: -1, row: -1 },
      data: [],
    };
  },
  components: {
    Cond,
    TableConf,
    Add,
    Detail,
    Examine,
    PrintConf,
    LocalPrint,
  },
  created() {
    this.getList();
    // this.getTypeList();
  },
  mounted() {
    if (this.$route.params.type) {
      if (this.$route.params.type === "detail") {
        this.propObj = {
          key: "split_id",
          value: this.$route.params.key,
        };
        this.detailVisiable = true;
      }
    }
  },
  methods: {
    handelCanceled(split_id) {
      this.$confirm("确定要删除该拆改单？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.noSockIds.includes(split_id) === true) {
          handelCanceled({
            type: "all",
            split_id: split_id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getList();
            this.currRow = {};
          }).catch(() => {
            this.currRow = {};
          });
        } else {
          cancelDel({
            split_id: split_id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getList();
            this.currRow = {};
          }).catch(() => {
            this.currRow = {};
          });
        }
      });
    },
    getSplitPrint(cond) {
      getSplitPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.currRow.select = "";
            this.currRow = {};
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: "创建人", value: data.create_user_name, width: "24%" },
              { txt: "创建时间", value: data.create_time, width: "24%" },
              { txt: "拆改单号", value: data.split_number, width: "30%" },
            ];
            const arr4 = [
              { txt: "门店", value: data.hierarchy_name, width: "24%" },
            ];
            res.data.bottom = [];
            const arr2 = [
              { txt: "备注", value: data.audit_remark, width: "100%" },
            ];
            const arr3 = [
              { txt: "审核状态", value: data.split_status_text, width: "24%" },
              { txt: "审核人", value: data.audit_user_name, width: "24%" },
              {
                txt: "审核时间",
                value: data.audit_time ? data.audit_time : "",
                width: "30%",
              },
            ];
            res.data.top.push(arr1, arr4);
            res.data.bottom.push(arr2, arr3);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split(".");
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== "index") {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list.push(sum);
            console.log(this.printData);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.currRow.select = "";
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    changeMode() {
      this.rowList = [];
      this.mode === 1 ? (this.mode = 2) : (this.mode = 1);
      this.page = 1;
      this.getList();
    },
    handleDalclick(row) {
      this.currRow = row;
      this.propObj = {
        key: "split_id",
        value: row.split_id,
      };
      this.detailVisiable = true;
      this.cancelTable();
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleHideExamine() {
      this.form = {};
      this.currRow = {};
      this.examineVisiable = false;
    },
    handleHideDetail() {
      this.detailVisiable = false;
      this.currRow = {};
    },
    handleHideAdd() {
      this.form = {};
      this.currRow = {};
      this.addVisiable = false;
      this.type = 'add';
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    // getTypeList() {
    //   getTypeListReq()
    //     .then((res) => {
    //       if (res.code === 1) {
    //         this.treeData = res.data;
    //         // 如果有数据，当前不是新增并且获取对应详情
    //         if (res.data.length) {
    //           this.currentKey = res.data[0].goods_type_id;
    //           this.currItem = res.data[0];
    //           this.select = this.handleArr(res.data);
    //         }
    //       }
    //     });
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功的操作
          this.addVisiable = true;
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
      this.tableVisiable2 = false;
      this.currRow.select = "";
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    getList() {
      this.loading = true;
      listReq({
        ...this.cond,
        page: this.page,
        limit: this.limit,
        mode: this.mode,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // res.data.list.forEach((item) => {
            //   item.create_time && (item.create_time = moment(item.create_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            //   item.audit_time && (item.audit_time = moment(item.audit_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            // });
            this.data = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.reasonVisible = false;
      this.form = {};
      this.cancelTable();
    },
    fetchData() {
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      listReq({
        ...this.cond,
        page: this.page,
        limit: this.limit,
        function_code: "split",
        export: 2,
      })
        .then((res) => {
          this.loading3 = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `拆改单列表.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = true;
        });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.type = "add";
      this.addVisiable = true;
    },
    /*
     *  检索
     */
    handleChange(val, row) {
      this.currRow = row;
      this.propObj = {
        key: "split_id",
        value: row.split_id,
      };
      if (val === 1) {
        this.detailVisiable = true;
        this.cancelTable();
      }
      if (val === 2) {
        this.examineVisiable = true;
        this.cancelTable();
      }
      if (val === 3) {
        this.reasonVisible = true;
      }
      if (val === 4) {
        if (this.noSockIds.includes(row.split_id) === true) {
          this.type = "sockEdit";
        } else {
          this.type = "edit";
        }
        this.addVisiable = true;
        this.cancelTable();
      }
      if (val === 5) {
        this.getSplitPrint({
          split_id: row.split_id,
        });
      }
      if (val === 20) {
        getIsLock({
          source: "split",
          primary_key_id: row.split_id,
        })
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "解锁成功！",
              });
              // this.addVisiable = true;
              row.select = "";
              this.noSockIds.push(row.split_id);
              this.currRow = {};
            }
          })
          .catch(() => {
            row.select = "";
            this.currRow = {};
          });
      }
      if (val === 22) {
        this.handelCanceled(row.split_id);
      }
    },
    /*
     *  分页操作
     */
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  关闭列表配置弹窗
     */
    // cancelTable() {
    //   const coordinate = { ...this.coordinate };
    //   coordinate.row = -1;
    //   coordinate.column = -1;
    //   this.coordinate = coordinate;
    //   this.tableVisiable = false;
    // },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
.middle {
  flex: 1;
}
</style>
