<template>
  <div class="wrapper">
    <div class="card">
      <el-page-header @back="goBack" :content="split_id ? '编辑拆改单' : '新增拆改单'"></el-page-header>
    </div>
    <div class="card" style="margin-top: 20px; display: flex; align-items: center">
      <el-button type="primary" icon="el-icon-plus" @click="handleAddHang">新增行</el-button>
      <el-button type="primary" @click="goodVisibel = true">筛选货品</el-button>
      <el-button type="primary" @click="copyVisible = true">粘贴货号</el-button>
      <div style="margin-left: 20px">
        注意：只有条码类型为
        <span style="color: #f56c6c">一码多货</span>才可以修改拆改数量，点击修改，按下Enter键完成。
      </div>
    </div>
    <div class="table" style="height: 465px; background: white">
      <u-table
        :max-height="465"
        :data="allot_goods"
        @cell-click="hanldeCellClick"
        :cell-class-name="getCellIndex"
      >
        <!-- <u-table-column type="selection" width="55"> </u-table-column> -->
        <template v-for="(item, index) in rowList">
          <u-table-column
            align="center"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.prop === 'goods_number' ? 300 : ''"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  (item.prop === 'num' &&
                    scope.row.goods_id &&
                    scope.row.goods_number_type === 20) ||
                  (item.prop === 'goods_number' && !scope.row.goods_id)
                "
              >
                <el-input
                  @blur="
                    handleBulr(
                      $event,
                      scope.row.index,
                      scope.column.index,
                      scope.row.goods_number,
                      item.prop,
                      scope.row.number_sum,
                    )
                  "
                  clearable
                  autofocus="true"
                  :id="'input' + scope.row.index + scope.column.index"
                  v-model="scope.row[item.prop]"
                  :placeholder="
                    item.prop === 'goods_number' ? '请输入条码号' : ''
                  "
                  v-show="
                    coordinate.column === scope.column.index &&
                    coordinate.row === scope.row.index
                  "
                  @keyup.enter.native="
                    handleKeydown(
                      $event,
                      scope.row[item.prop],
                      item.prop,
                      scope.row.index,
                      scope.column.index,
                      scope.row.num
                    )
                  "
                ></el-input>
                <span
                  v-show="
                    scope.row.index !== coordinate.row ||
                    scope.column.index !== coordinate.column
                  "
                >
                  {{ scope.row[item.prop] }}
                  <span v-if="!scope.row[item.prop]" style="color: #ccc">
                    {{
                    item.prop === "goods_number"
                    ? "请点击输入条码号，按下Enter键结束"
                    : ""
                    }}
                  </span>
                </span>
              </template>
              <template v-else>
                <!-- {{
                  item.prop === "goods_number_type"
                    ? scope.row[item.prop] === 10
                      ? "一码一货"
                      : scope.row[item.prop] === 20
                      ? "一码多货"
                      : ""
                    : scope.row[item.prop]
                }}-->
                <template v-if="item.prop === 'goods_number_type'">
                  {{
                  scope.row[item.prop] === 10
                  ? "一码一货"
                  : scope.row[item.prop] === 20
                  ? "一码多货"
                  : ""
                  }}
                </template>
                <template v-else-if="item.prop === 'goods_name'">
                  {{ scope.row[item.prop] }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-left: 5px; height: 15px" />
                  </template>
                </template>
                <!-- <template v-else-if="item.prop === 'num'">
                <el-input
                  v-model="scope.row.num"
                  :disabled="scope.row.goods_number_type === 20 ? false : true"
                  @blur="
                    handleBulr(
                      $event,
                      scope.row.index,
                      scope.column.index,
                      scope.row.goods_number,
                      item.prop,
                      scope.row.number_sum,
                    )
                  "
                />
              </template> -->
                <template v-else-if="item.prop === 'sale_warehouse'">
                  <template
                    v-if="
                    scope.row.stockList &&
                    scope.row.stockList.length !== 0 &&
                    scope.row.goods_number_type === 20
                  "
                  >
                    <el-select
                      v-model="scope.row.sale_warehouse_id"
                      style="width: 100%; margin-right: 10px"
                      @change="
                      (e) =>
                        handleSelectStock(
                          e,
                          scope.row.index,
                          scope.row.stockList,
                          scope.row.sale_warehouse_id
                        )
                    "
                    >
                      <el-option
                        v-for="item in scope.row.stockList"
                        :key="item.warehouse_id"
                        :value="item.warehouse_id"
                        :label="item.warehouse_name"
                      ></el-option>
                    </el-select>
                  </template>
                  <template v-else-if="scope.row.goods_number_type === 10">
                    {{
                    scope.row.warehouse_name
                    }}
                  </template>
                  <template v-else>--</template>
                </template>
                <template v-else>{{ scope.row[item.prop] }}</template>
              </template>
            </template>
          </u-table-column>
        </template>
        <u-table-column align="center" prop="operation" label="操作" width="180">
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="handleDele(scope.row.index)">删除</el-button>
            </div>
          </template>
        </u-table-column>
      </u-table>
    </div>

    <div class="table total" style="border-top: 0px">
      <div class="total-left">合计</div>
      <div class="total-right">
        <div class="total-right-item">
          数量：
          <span>{{ total || "0" }}</span>
        </div>
        <div class="total-right-item">
          金重(g)：
          <span>{{ weight || "0.00" }}</span>
        </div>
        <div class="total-right-item">
          标签价：
          <span>{{ sale_price || "0.0000" }}</span>
        </div>
      </div>
    </div>
    <div class="remark">
      <div style="width: 50px">备注</div>
      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 3 }"
        placeholder="请输入内容"
        v-model="remark"
      ></el-input>
    </div>
    <el-form :model="formTime" inline style="margin-bottom: 80px;">
      <el-form-item label="创建时间" v-if="type === 'sockEdit'">
        <el-date-picker
          v-model="formTime.create_time"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="审核时间" v-if="type === 'sockEdit'">
        <el-date-picker
          v-model="formTime.audit_time"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <!-- <Add :allot_goods="this.allot_goods" /> -->
    <!-- <div class="form" style="margin-bottom: 80px;">
      <el-form :model="form" label-width="80px">
        <el-form-item label="调入门店" required>
          <el-select v-model="form.to_hierarchy_id" clearable>
            <template v-for="item in merchantList">
              <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.allot_remark"
            type="textarea"
            :autosize="{ minRows: 8 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>-->
    <div class="btns">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmit"
        :loading="loading"
        v-if="type === 'add' || type === 'edit'"
      >提交审核</el-button>
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmitSock"
        :loading="loading"
        v-if="type === 'sockEdit'"
      >保存并锁定</el-button>
      <el-button type="primary" plain @click="handleCancel">取消</el-button>
    </div>
    <el-dialog title="粘贴货号" :visible.sync="copyVisible" width="30%" :before-close="handleCopyHide">
      <el-input
        v-if="!copyErrVisible"
        type="textarea"
        v-model="copyString"
        :autosize="{ minRows: 8, maxRows: 8 }"
      ></el-input>
      <div v-else>
        <div v-for="(item, index) in noCopyArr" :key="index">{{index + 1}}.{{item.txt}}</div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!copyErrVisible">
        <el-button @click="handleCopyHide">取 消</el-button>
        <el-button type="primary" @click="handleCopy" :loading="copyLoading">确 定</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="handleCopyHide">取 消</el-button>
        <el-button type="primary" @click="handleCopyContinue">粘贴可操作货号</el-button>
      </span>
    </el-dialog>
    <choose-goods
      :goodVisibel="goodVisibel"
      @close="goodVisibel = false"
      @getGoods="handleGoods"
      :formItemList="formItemList"
      :selectGoods="isMa_goods"
      v-if="goodVisibel"
      query_type="check_goods"
    />
  </div>
</template>

<script>
import storage from "good-storage";
import chooseGoods from "@/components/goods/chooseGoods/Index.vue";
import { getMerchantListReq } from "@/api/authority/menage";
import { createReq } from "@/api/goods/goodsHandler/change/add";
import { canceledUpdate } from "@/api/goods/goodsHandler/change/list";
import { getGoodsNumberStockReq } from "@/api/order/offlineOrder/add";
import goodsReq from "@/components/goods/chooseGoods/index";
import Add from "@/components/goods/add/Index.vue";
import {
  detailReq,
  detailGoodsListReq
} from "@/api/goods/goodsHandler/change/detail";
import numeral from "numeral";
import __tag__ from "@/utils/tag.js";

export default {
  data() {
    this.tag = __tag__;
    return {
      isMa_goods: [],
      formTime: {
        create_time: "",
        audit_time: ""
      },
      canCopyArr: [], // 可粘贴的货号
      noCopyArr: [],
      copyErrVisible: false,
      copyLoading: false,
      copyString: "",
      copyVisible: false,
      remark: "",
      loading: false,
      total: "",
      weight: "",
      sale_price: "",
      merchantList: [],
      formItemList: [
        {
          label: "品类",
          placeholder: "请选择",
          attr: "goods_type_id",
          type: "treeSelect"
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input"
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input"
        },
        {
          label: "盘点单号",
          placeholder: "请填写盘点单号",
          attr: "check_number",
          type: "input"
        },
        {
          label: "调拨单号",
          placeholder: "请填写调拨单号",
          attr: "allot_number",
          type: "input"
        },
        {
          label: "调仓单号",
          placeholder: "请填写调仓单号",
          attr: "allot_warehouse_number",
          type: "input",
        },
        {
          label: "修改单号",
          placeholder: "请填写修改单号",
          attr: "edit_number",
          type: "input"
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input"
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input"
        },
        { type: "hierarchy_id", label: "所属门店" },
        { type: "warehouse_id", label: "仓库" },
        { type: "101", label: "款式" },
        { type: "100", label: "材质成色" },
        { type: "103", label: "品牌" },
        { type: "sale_price", label: "标签价" },
        { type: "104", label: "净金重" },
        { type: "entry_time", label: "入库时间" }
        // {
        //   label: "调拨单号",
        //   placeholder: "请填写调拨单号",
        //   attr: "allot_id",
        //   type: "input",
        // },
      ],
      userInfo: {},
      formInline: {},
      form: {},
      arr: [
        {
          index: "",
          goods_number: "",
          goods_name: "",
          101: "",
          102: "",
          103: "",
          104: "",
          sale_price: "",
          num: ""
        }
      ],
      goodVisibel: false,
      infoVisibel: false,
      tableVisiable: false,
      dialogVisible: false,
      rowList: [
        {
          prop: "index",
          label: "序号"
        },
        {
          prop: "goods_number",
          label: "货号"
        },
        {
          prop: "goods_name",
          label: "品名"
        },
        {
          prop: "goods_type_name",
          label: "品类"
        },
        {
          prop: "102",
          label: "产地"
        },
        {
          prop: "103",
          label: "品牌"
        },
        {
          prop: "104",
          label: "金重"
        },
        {
          prop: "sale_warehouse",
          label: "仓库"
        },
        {
          prop: "sale_price",
          label: "标签价"
        },
        {
          prop: "goods_number_type",
          label: "货品类型"
        },
        {
          prop: "num",
          label: "拆改数量"
        }
      ],
      coordinate: { column: -1, row: -1 },
      originData: {}
    };
  },
  components: {
    chooseGoods,
    Add
  },
  props: {
    split_id: Number,
    type: String
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
    this.allot_goods = [...this.arr];
    if (this.type === "edit" || this.type === "sockEdit") {
      // this.getDetail();
      this.getDetailGoods();
    } else {
      this.coordinate = { row: 1, column: 1 };
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + this.coordinate.row + this.coordinate.column
          );
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    }
    // 记录原来的数量
    this.handleOriginData();
  },
  methods: {
    /*
     *  选择的仓库
     */
    handleSelectStock(e, index, list, warehouse_id) {
      let num = list.find(item => item.warehouse_id === e).stock;
      this.allot_goods = this.allot_goods.map((item, i) => {
        if (i === index - 1) {
          item.num = num;
          item.number_sum = num;
          item.warehouse_id = warehouse_id;
          if (
            (this.type === "edit" && item.split_id) ||
            (this.type === "sockEdit" && item.split_id)
          ) {
            item.split_id = null;
          }
        }
        console.log(item);
        return item;
      });
      console.log(this.allot_goods)
      this.handleSum();
    },
    handleCopyContinue() {
      if (!this.canCopyArr.length) {
        this.$message.warning("没有可粘贴货号");
      }
      this.handleGoods(this.canCopyArr);
      this.copyString = "";
      this.canCopyArr = [];
      this.handleCopyHide();
    },
    handleCopyHide() {
      this.copyVisible = false;
      setTimeout(() => {
        this.copyErrVisible = false;
      }, 500);
    },
    // 处理zhantie
    handleCopy() {
      this.copyLoading = true;
      let arr = this.copyString.split(/[\r\b\t\n\f]/g);
      const canCopyArr = [];
      const noCopyArr = [];
      arr = arr.filter(item => {
        if (item) {
          return item;
        }
      });
      arr.forEach(item => {
        if (item) {
          // 去搜索 需要看结果是否符合条件
          goodsReq({
            goods_number: item,
            allot_state: 1
            // query_type: "check_goods",
          }).then(res => {
            if (res.data.list.length) {
              // 是否符合条件
              // 必须是同仓库的
              if (res.data.list[0].usable_count) {
                canCopyArr.push(res.data.list[0]);
              } else {
                if (res.data.list[0].goods_tag_id) {
                  const goods_tag_id = res.data.list[0].goods_tag_id.split(",");
                  // 不能选择的标志
                  const tagList = [
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "9",
                    "10",
                    "11",
                    "12"
                  ];
                  goods_tag_id.forEach(tag_id => {
                    tagList.forEach(tag => {
                      if (tag_id === tag) {
                        noCopyArr.push({
                          txt: `货号 ${item} 正在${this.tag[tag_id]}，不可粘贴`
                        });
                      }
                    });
                  });
                }
              }
            } else {
              noCopyArr.push({
                txt: `货号 ${item} 不存在`
              });
            }
            if (canCopyArr.length + noCopyArr.length === arr.length) {
              this.copyLoading = false;
              if (!noCopyArr.length) {
                this.handleGoods(canCopyArr);
                this.copyVisible = false;
                this.copyString = "";
              } else {
                // 弹框哪些不能复制
                this.canCopyArr = canCopyArr;
                this.noCopyArr = noCopyArr;
                this.copyString = "";
                this.copyErrVisible = true;
              }
            }
          });
        }
      });
    },
    handleSubmitSock() {
      const goods_number = [];
      const number = [];
      const arr_split_id = [];
      const warehouse_id = [];
      let obj = {};
      this.allot_goods.forEach((item, index) => {
        if (item.goods_id) {
          goods_number[index] = item.goods_number;
          number[index] = item.num;
          arr_split_id[index] = item.split_id || 0;
          warehouse_id[index] = item.warehouse_id || 0;
        }
      });
      if (!goods_number.length) {
        this.$message.error("请添加货品数据");
        return;
      }
      this.loading = true;
      canceledUpdate({
        ...this.formTime,
        arr_split_id,
        number,
        goods_number,
        warehouse_id,
        split_id: this.split_id,
        split_remark: this.remark
      })
        .then(res => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
            this.$emit("getList");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 聚焦
    handleFocus(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row;
      coordinate.column = column;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          dom.value = "";
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    handleAddHang() {
      this.allot_goods.push({
        index: "",
        goods_number: "",
        goods_name: "",
        101: "",
        102: "",
        103: "",
        104: "",
        sale_price: "",
        num: ""
      });
    },
    handleOriginData() {
      goodsReq({
        allot_state: 1,
        page: 1,
        limit: 999
      }).then(res => {
        if (res.code === 1) {
          const originData = {};
          res.data.list.forEach(item => {
            originData[item.goods_number] = item.num;
          });
          this.originData = originData;
        }
      });
    },
    handleBulr(e, row, column, goods_number, prop, number_sum) {
      console.log(number_sum)
      if (prop === "num") {
        if (!e.target.value) {
          this.$message.error("请输入拆改数量");
          const coordinate = { ...this.coordinate };
          coordinate.row = row;
          coordinate.column = column;
          this.coordinate = coordinate;
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          this.$nextTick(() => {
            try {
              dom.focus();
            } catch (error) {}
          });
          return;
        }
        if (Number(e.target.value) > Number(number_sum)) {
          this.$message.error("货品数量不能超过原有的仓库数量");
          const coordinate = { ...this.coordinate };
          coordinate.row = row;
          coordinate.column = column;
          this.coordinate = coordinate;
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          this.$nextTick(() => {
            try {
              dom.focus();
            } catch (error) {}
          });
          return;
        }
        this.coordinate = {
          column: -1,
          row: -1,
        };
        // 限制不能超过最大值
        this.handleSum();
        return;
      }
      this.coordinate = {
        column: -1,
        row: -1
      };
    },
    getDetail() {
      detailReq(this.split_id).then(res => {
        if (res.code === 1) {
          // res.data.to_hierarchy_id = res.data.to_hierarchy_id.toString();
          this.form = res.data;
        }
      });
    },
    getDetailGoods() {
      detailGoodsListReq({
        split_id: this.split_id,
        page: 1,
        page_num: 999999
      }).then(res => {
        if (res.code === 1) {
          this.formTime.create_time = res.data.create_time;
          this.formTime.audit_time = res.data.audit_time;
          // res.data.list.forEach(item => {
          //   // item.num = item.total_num;
          //   this.allot_goods.unshift(item);
          //   if (this.split_id) {
          //     this.handleSum();
          //   }
          // });
          this.remark = res.data.remark;
          this.handleGoods(res.data.list, "edit");
        }
      });
    },
    // 提交审核
    handleSubmit() {
      const newArr = [];
      let obj = {};
      console.log(this.allot_goods);
      this.allot_goods.forEach(item => {
        if (item.goods_id) {
          obj = {
            goods_id: item.goods_id,
            num: item.num,
            allot_goods_id: item.split_id,
            warehouse_id: item.warehouse_id
          };
          newArr.push(obj);
        }
      });
      if (!newArr.length) {
        this.$message.error("请添加货品数据");
        return;
      }
      // if (!this.form.to_hierarchy_id) {
      //   this.$message.error('请选择门店');
      //   return;
      // }
      this.loading = true;
      createReq({
        list: newArr,
        total_num: this.total,
        split_id: this.split_id,
        remark: this.remark
      })
        .then(res => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
            this.$emit("getList");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleDele(index) {
      if (this.type === "sockEdit") {
        this.$confirm("确定要删除吗？删除后将无法恢复", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.allot_goods.forEach((item, _index) => {
            if (index === _index + 1) {
              this.allot_goods.splice(_index, 1);
            }
          });
          this.handleSum();
        });
      } else {
        this.allot_goods.forEach((item, _index) => {
          if (index === _index + 1) {
            this.allot_goods.splice(_index, 1);
          }
        });
        this.handleSum();
      }
    },
    handleSum() {
      console.log("执行");
      let total = 0;
      let weight = 0;
      let sale_price = 0;
      this.allot_goods.forEach(item => {
        if (item.goods_id) {
          total += Number(item.num);
          if (item["104"]) {
            weight += Number(item["104"]) * item.num;
          }
          sale_price += Number(item.sale_price) * item.num;
        }
      });
      this.total = total;
      this.weight = numeral(weight).format("0.00");
      this.sale_price = numeral(sale_price).format("0.00");
    },
    handleCancel() {
      this.$emit("hide");
    },
    handleKeydown(val, value, prop, row, column, goods_number) {
      if (!value && prop !== "num") {
        this.$message.error("请填入货号");
        return;
      }
      if (value > this.originData[goods_number] && prop === "num") {
        this.$message.error("货品数量不能超过原有的仓库数量");
        return;
      }
      // if (value > num) {
      //   this.$message.error("编辑不能超过原有的数量");
      //   return;
      // }
      if (val.keyCode === 13) {
        if (prop === "goods_number") {
          if (!value) {
            return;
          }
          // 请求接口
          goodsReq({ goods_number: value, allot_state: 1 })
            .then(res => {
              if (res.code === 1) {
                if (res.data.list.length === 0) {
                  this.$message.warning("查询不到该货号");
                }
                const data = {
                  goods_number: value
                };
                if (this.type === "sockEdit") {
                  data.cancel_id = this.cancel_id;
                  data.isAll = 0;
                }
                getGoodsNumberStockReq(data).then(r => {
                  if (r.code === 1) {
                    r.data = r.data.map(item => {
                      return {
                        ...item,
                        warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                      };
                    });
                    res.data.list[0].stockList = r.data;
                    res.data.list[0].sale_warehouse_id =
                      res.data.list[0].warehouse_id;
                    if (res.data.list[0].goods_number_type === 20) {
                      r.data.forEach(item => {
                        if (
                          res.data.list[0].warehouse_id === item.warehouse_id
                        ) {
                          res.data.list[0].num = item.stock;
                          res.data.list[0].number_sum = res.data.list[0].num;
                        }
                      });
                    } else {
                      res.data.list[0].num = res.data.list[0].num;
                      res.data.list[0].number_sum = res.data.list[0].num;
                    }

                    if (!res.data.total) {
                      this.$message.warning("查询不到该货号");
                      this.allot_goods[row - 1].goods_number = "";
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row;
                      coordinate.column = column;
                      this.coordinate = coordinate;

                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );
                          dom.value = "";
                          dom.focus();
                        } catch (error) {}
                      });
                      return;
                    }
                    let breakFlg = false;
                    if (res.data.list[0].goods_tag_id) {
                      const goods_tag_id = res.data.list[0].goods_tag_id.split(
                        ","
                      );
                      // 不能选择的标志
                      const tagList = [
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "9",
                        "10",
                        "11",
                        "12"
                      ];
                    }
                    if (breakFlg) {
                      return;
                    }
                    // 数据是否有重复
                    const info = this.allot_goods.find(
                      item => item.goods_number === value && item.goods_id
                    );
                    if (info && info.goods_number_type === 10) {
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row;
                      coordinate.column = column;
                      this.coordinate = coordinate;
                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );
                          dom.value = "";
                          dom.focus();
                        } catch (error) {}
                      });
                      this.$message.warning("该数据已存在。");
                    } else {
                      this.allot_goods.splice(row - 1, 1, ...res.data.list);
                      // 新增一条空数据
                      this.allot_goods.push({
                        index: "",
                        goods_number: "",
                        goods_name: "",
                        101: "",
                        102: "",
                        103: "",
                        104: "",
                        sale_price: "",
                        num: "",
                        stockList: []
                      });
                      this.handleSum();
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row + 1;
                      coordinate.column = column;
                      this.coordinate = coordinate;
                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );
                          dom.focus();
                        } catch (error) {}
                      });
                    }
                  }
                });
              }
            })
            .catch(() => {
              const coordinate = { ...this.coordinate };
              coordinate.row = row;
              coordinate.column = column;
              this.coordinate = coordinate;
              this.$nextTick(() => {
                try {
                  const dom = document.getElementById(
                    "input" + coordinate.row + coordinate.column
                  );
                  dom.value = "";
                  dom.focus();
                } catch (error) {}
              });
            });
        }
        if (prop === "num") {
          this.handleSum();
        }
        this.coordinate = {
          column: -1,
          row: -1
        };
      }
    },

    // handleKeydown(val, value, prop, row, column, goods_number) {
    //   if (!value && prop !== "num") {
    //     this.$message.error("请填入货号");
    //     return;
    //   }
    //   if (!value && prop === "num") {
    //     this.$message.error("请填入拆改数量");
    //     return;
    //   }
    //   if (value > this.originData[goods_number] && prop === "num") {
    //     this.$message.error("编辑不能超过原有的数量");
    //     return;
    //   }
    //   if (val.keyCode === 13) {
    //     // if (!/^[0-9]*$/.test(value)) {
    //     //   this.$message.error('请输入数字');
    //     // } else {
    //     // }
    //     if (prop === "goods_number") {
    //       if (!value) {
    //         return;
    //       }
    //       // 请求接口
    //       goodsReq({
    //         goods_number: value,
    //         allot_state: 1,
    //         query_type: "check_goods"
    //       })
    //         .then(res => {
    //           if (res.code === 1) {
    //             if (!res.data.total) {
    //               this.$message.warning("查询不到该货号");
    //               this.allot_goods[row - 1].goods_number = "";
    //               const coordinate = { ...this.coordinate };
    //               coordinate.row = row;
    //               coordinate.column = column;
    //               this.coordinate = coordinate;
    //               this.$nextTick(() => {
    //                 try {
    //                   const dom = document.getElementById(
    //                     "input" + coordinate.row + coordinate.column
    //                   );
    //                   dom.value = "";
    //                   dom.focus();
    //                 } catch (error) {
    //                   // console.log('无法聚焦');
    //                 }
    //               });
    //               return;
    //             }
    //             let breakFlg = false;
    //             if (res.data.list[0].goods_tag_id) {
    //               const goods_tag_id = res.data.list[0].goods_tag_id.split(",");
    //               // 不能选择的标志
    //               const tagList = [
    //                 "3",
    //                 "4",
    //                 "5",
    //                 "6",
    //                 "7",
    //                 "9",
    //                 "10",
    //                 "11",
    //                 "12"
    //               ];
    //               goods_tag_id.forEach(item => {
    //                 tagList.forEach(tag => {
    //                   if (item === tag) {
    //                     // 防止一码多货，需要判断可操作为0
    //                     if (!res.data.list[0].usable_count) {
    //                       this.$message.warning(
    //                         `该货品状态：${this.tag[item]}，不可操作`
    //                       );
    //                       this.allot_goods[row - 1].goods_number = "";
    //                       this.handleFocus(row, column);
    //                       breakFlg = true;
    //                     }
    //                   }
    //                 });
    //               });
    //             }
    //             if (breakFlg) {
    //               return;
    //             }
    //             // 数据是否有重复
    //             let m = 0;
    //             this.allot_goods.forEach(item => {
    //               if (item.goods_id !== res.data.list[0].goods_id) {
    //                 m += 1;
    //                 if (m === this.allot_goods.length || res.data.list[0].goods_number_type === 20) {
    //                   this.allot_goods.splice(row - 1, 1, ...res.data.list);
    //                   // 新增一条空数据
    //                   this.allot_goods.push({
    //                     index: "",
    //                     goods_number: "",
    //                     goods_name: "",
    //                     101: "",
    //                     102: "",
    //                     103: "",
    //                     104: "",
    //                     sale_price: "",
    //                     num: ""
    //                   });
    //                   this.handleSum();
    //                   const coordinate = { ...this.coordinate };
    //                   coordinate.row = row + 1;
    //                   coordinate.column = column;
    //                   this.coordinate = coordinate;
    //                   this.$nextTick(() => {
    //                     try {
    //                       const dom = document.getElementById(
    //                         "input" + coordinate.row + coordinate.column
    //                       );
    //                       dom.focus();
    //                     } catch (error) {
    //                       // console.log('无法聚焦');
    //                     }
    //                   });
    //                 }
    //               } else {
    //                 if (res.data.list[0].goods_number_type === 10) {
    //                   this.$message.warning("该数据已存在。");
    //                   const coordinate = { ...this.coordinate };
    //                   coordinate.row = row;
    //                   coordinate.column = column;
    //                   this.coordinate = coordinate;
    //                   this.$nextTick(() => {
    //                     try {
    //                       const dom = document.getElementById(
    //                         "input" + coordinate.row + coordinate.column
    //                       );
    //                       dom.value = "";
    //                       dom.focus();
    //                     } catch (error) {
    //                       // console.log('无法聚焦');
    //                     }
    //                   });
    //                 }
    //               }
    //             });
    //             // 请求仓库
    //              if (this.type === "edit" || this.type === "sockEdit") {
    //               data.cancel_id = this.cancel_id;
    //               data.isAll = 0;
    //             }
    //             const data = {
    //               goods_number: value,
    //             };

    //             getGoodsNumberStockReq(data).then((r) => {
    //               if (r.code === 1) {
    //                 r.data = r.data.map((item) => {
    //                   return {
    //                     ...item,
    //                     warehouse_name: `${item.warehouse_name}(${item.stock}件)`,
    //                   };
    //                 });
    //                 res.data.list[0].stockList = r.data;
    //                 res.data.list[0].sale_warehouse_id =
    //                   res.data.list[0].warehouse_id;
    //                 if (res.data.list[0].goods_number_type === 20) {
    //                   r.data.forEach((item) => {
    //                     if (
    //                       res.data.list[0].warehouse_id === item.warehouse_id
    //                     ) {
    //                       res.data.list[0].num = item.stock;
    //                     }
    //                   });
    //                 } else {
    //                   res.data.list[0].num = res.data.list[0].num;
    //                 }
    //               }
    //             })
    //           }
    //         })
    //         .catch(() => {
    //           const coordinate = { ...this.coordinate };
    //           coordinate.row = row;
    //           coordinate.column = column;
    //           this.coordinate = coordinate;
    //           this.$nextTick(() => {
    //             try {
    //               const dom = document.getElementById(
    //                 "input" + coordinate.row + coordinate.column
    //               );
    //               dom.value = "";
    //               dom.focus();
    //             } catch (error) {
    //               // console.log('无法聚焦');
    //             }
    //           });
    //         });
    //     }
    //     if (prop === "num") {
    //       this.handleSum();
    //     }
    //     this.coordinate = {
    //       column: -1,
    //       row: -1
    //     };
    //   }
    // },
    getMerchantList() {
      getMerchantListReq(1).then(res => {
        if (res.code === 1) {
          this.merchantList = res.data;
        }
      });
    },
    // handleGoods(goods) {
    //   this.allot_goods = [...goods, ...this.allot_goods];
    //   // 去重
    //   let newArr = [];
    //   let obj = {};
    //   this.allot_goods.forEach((item) => {
    //     const { goods_id } = item;
    //     if (!obj[goods_id]) {
    //       obj[goods_id] = true;
    //       const newItem = { ...item };
    //       newArr.push(newItem);
    //     }
    //   });
    //   this.allot_goods = newArr;
    //   this.handleSum();
    // },
    async handleGoods(goods, type) {
      this.allot_goods = [...goods, ...this.allot_goods];
      // 去重
      let newArr = [];
      let obj = {};
      for (let i = 0; i < this.allot_goods.length; i++) {
        if (this.allot_goods[i].goods_number) {
          const data = {
            goods_number: this.allot_goods[i].goods_number
          };
          if (this.type === 'edit') {
            data.warehouse_id = this.allot_goods[i].warehouse_id;
          }
          if (this.type === "sockEdit") {
            data.split_id = this.split_id;
            data.isAll = 0;
          }
          const res = await getGoodsNumberStockReq(data);
          res.data = res.data.map(item => {
            return {
              ...item,
              warehouse_name: `${item.warehouse_name}(${item.stock}件)`
            };
          });
          this.allot_goods[i].stockList = res.data;
          this.allot_goods[i].sale_warehouse_id = this.allot_goods[
            i
          ].warehouse_id;
          if (this.type === "edit" || this.type === "sockEdit") {
            if (type && type === "edit") {
              res.data.forEach(items => {
                if (
                  this.allot_goods[i].sale_warehouse_id === items.warehouse_id
                ) {
                  this.allot_goods[i].number_sum = items.stock;
                }
              });
            }
          }
          if (!type) {
            res.data.forEach(items => {
              if (
                this.allot_goods[i].sale_warehouse_id === items.warehouse_id
              ) {
                this.allot_goods[i].num = items.stock;
              }
            });
          } else {
            // this.allot_goods[i].num = this.allot_goods[i].stock;
          }
        }

        if (!type) {
          this.allot_goods[i].number_sum = this.allot_goods[i].num;
        }

        const { goods_id } = this.allot_goods[i];
        // if (!obj[goods_id]) {
        //   obj[goods_id] = true;
        //   const newItem = { ...this.allot_goods[i] };
        //   newArr.push(newItem);
        // }
        // 不去重
        const newItem = { ...this.allot_goods[i] };
        newArr.push(newItem);
      }
      this.allot_goods = newArr;
      this.isMa_goods = this.allot_goods.filter(item => {
        return item.goods_number_type === 10;
      });
      console.log(this.allot_goods[0].number_sum);
      this.handleSum();
    },
    onSubmit() {},
    handleClose() {
      this.dialogVisible = false;
      this.infoVisibel = false;
      this.goodVisibel = false;
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + row.index + column.index
          );
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
    }
  }
};
</script>

<style lang="less" scoped>
.remark {
  width: 50%;
  padding: 20px;
  display: flex;
}
/deep/ .el-button--text {
  color: #f56c6c;
}
.form {
  background: white;
  padding: 20px 10px;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-top: 1px solid #ddd;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  &-left {
  }
  &-right {
    display: flex;
    &-item {
      font-size: 14px;
      padding: 0 10px;
      span {
        color: #fd563a;
        font-weight: bold;
      }
    }
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.tip {
  margin-bottom: 30px;
  margin-left: 20px;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  line-height: 80px;
  font-weight: bold;
  font-size: 16px;
}
</style>
