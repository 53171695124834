<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="拆改单审核"> </el-page-header>
    </div>
    <div class="table">
      <el-table :data="tableData.list" :row-class-name="handleRow" v-loading="loading">
        <template v-for="(item, index) in rowList">
          <el-table-column
            :key="index"
            :label="item.label"
            :prop="item.prop"
            align="center"
          >
            <template v-slot="scope">
              <template v-if="item.isImg">
                <el-image
                  :src="scope.row[item.prop]"
                  :preview-src-list="[scope.row[item.prop]]"
                >
                  <div slot="error" class="image-slot">
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </el-image>
              </template>
              <template v-else-if="item.prop === 'goods_name'">
                <div style="display: flex; align-items: center;">
                  {{scope.row.goods_name}}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-left: 5px; height: 15px;" />
                  </template>
                </div>
              </template>
              <template v-else>{{ scope.row[item.prop] || '--'}}</template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="pagina">
        <el-pagination
          :current-page="page"
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <div style="padding: 20px; display: flex; margin-bottom: 80px;">
      <div style="width: 50px;">备注：</div>
      <div style="flex: 1;">{{this.tableData.remark || '--'}}</div>
    </div>
    <div class="btns">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="toPass"
        :loading="loading"
      >
        审核通过
      </el-button>
      <el-button type="primary" plain @click="toFail">审核不通过</el-button>
    </div>
    <el-dialog
      title="选择调入仓库"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
      <el-form :model="form">
        <el-form-item label="选择调入仓库">
          <el-select v-model="form.warehouse_id" clearable>
            <template v-for="item in warehouseList">
              <el-option
                :label="item.warehouse_name"
                :value="item.warehouse_id"
                :key="item.warehouse_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="请输入原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 4 }"
        placeholder="请输入原因"
        v-model="split_remark"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleReason">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from "good-storage";
import { detailGoodsListReq } from "@/api/goods/goodsHandler/change/detail.js";
import { auditReq } from "@/api/goods/goodsHandler/change/examine.js";
import { listReq } from "@/api/goods/goodsStore/list";

export default {
  name: "examine",
  data() {
    return {
      page: 1,
      limit: this.$store.state.pageSizes[0],
      split_remark: "", // 备注
      reasonVisible: false,
      warehouseList: [],
      form: {},
      dialogVisible: false,
      loading: false,
      userInfo: {},
      tableData: {},
      rowList: [
        {
          label: "序号",
          prop: "index",
        },
        {
          label: "图片",
          prop: "goods_pic",
          isImg: true,
        },
        {
          label: "货号",
          prop: "goods_number",
        },
        {
          label: "品名",
          prop: "goods_name",
        },
        {
          label: "品类",
          prop: "goods_type_id",
        },
        {
          label: "产地",
          prop: "102",
        },
        {
          label: "品牌",
          prop: "103",
        },
        {
          label: '仓库',
          prop: 'warehouse_name',
        },
        {
          label: "金重(g)",
          prop: "104",
        },
        {
          label: "货品数量",
          prop: "total_num",
        },
        {
          label: "标签价",
          prop: "sale_price",
        },
      ],
    };
  },
  props: {
    split_id: Number,
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getDetailGoods();
    this.getList();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getDetailGoods();
    },
    // 改变分页条数
    handleSizeChange(limit) {
      this.limit = limit;
      this.getDetailGoods();
    },
    handleClose() {
      this.reasonVisible = false;
      this.dialogVisible = false;
      this.audit_content = "";
      this.form = {};
    },
    handleTrue() {
      this.dialogVisible = false;
      this.handleRecipient(40);
    },
    getList() {
      listReq({ page: 1, limit: 999999 }).then((res) => {
        if (res.code === 1) {
          this.warehouseList = res.data.list;
        }
      });
    },
    handleAudit(split_status) {
      this.loading = true;
      auditReq({
        split_id: this.split_id,
        split_status: split_status,
        split_remark: this.split_remark,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
            this.$emit("getList");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleRecipient(allot_status) {
      this.loading = true;
      recipientReq({
        split_id: this.split_id,
        allot_status: allot_status,
        warehouse_id: this.form.warehouse_id,
        audit_content: this.audit_content,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
            this.$emit("getList");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toPass() {
      this.$confirm("确定要审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 审核通过
        this.handleAudit(20);
      });
    },
    handleReason() {
      this.split_remark = this.split_remark.trim();
      if (!this.split_remark) {
        this.$message.error("请输入原因");
        return;
      }
      this.handleAudit(30);
    },
    toFail() {
      if (this.activeName === "first") {
        this.reasonVisible = true;
        // this.$confirm('确定要审核不通过吗?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // }).then(() => {
        //   // 审核不通过
        //   this.handleAudit(20);
        // });
      } else {
        this.reasonVisible = true;
        // this.$confirm('确定要拒绝签收吗?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // }).then(() => {
        //   // 拒绝签收
        //   this.handleRecipient(50);
        // });
      }
    },
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    getDetailGoods() {
      this.loading = true;
      detailGoodsListReq({ split_id: this.split_id, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-image {
  margin-top: 3px;
  width: 58px;
  height: 58px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
</style>
