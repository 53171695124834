import { post } from '@/utils/request.js';

const auditReq = ({ split_id, split_status, split_remark }) => post({
  url: '/store/goods.handling.Split/audit',
  data: {
    split_id,
    split_status,
    split_remark,
  },
});

export {
  auditReq,
};
